<template>
    <main class="page page--contact contact">
        <section class="page__content">
            <h1>Contacteer V-Tax</h1>
            <h2>Contactgegevens</h2>
            <section class="contact__block">
                <table>
                    <tr>
                        <td>Taxi</td>
                        <td>
                            <p><a href="tel:092222222">09/222.22.22</a></p>
                            <p><a href="tel:092232323">09/223.23.23</a></p>
                            <p><a href="tel:092252525">09/225.25.25</a></p>
                            <p><a href="tel:080022225">0800/222.25</a> Gratis nummer (enkel met vaste lijn)</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Receptie</td>
                        <td><p><a href="tel:092223323">09/222.33.23</a></p></td>
                    </tr>
                    <tr>
                        <td>Fax</td>
                        <td><p><a href="tel:092224436">09/222.44.36</a></p></td>
                    </tr>
                    <tr>
                        <td>E-mail</td>
                        <td>
                            <p><a href="mailto:info@v-tax.be">info@v-tax.be</a></p>
                        </td>
                    </tr>
                    <tr>
                        <td>Adres</td>
                        <td>
                            <p>V-Tax BV</p>
                            <p>Wiedauwkaai 98</p>
                            <p>9000 Gent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>BTW</td>
                        <td>BE 0478770422</td>
                    </tr>
                </table>
            </section>
            <h2>Verloren voorwerpen</h2>
            <section class="contact__block">
                <p>Voorwerpen die U verliest in de taxi worden door de chauffeur na het einde van zijn dienst afgegeven
                    op onze taxicentrale, waar ze ingeschreven worden in een register.</p>
                <p>Mocht de chauffeur niets gevonden hebben, dan is er nog steeds de onderhoudsploeg die de wagens
                    dagelijks reinigt. <b>Reageer zo snel mogelijk</b>. Indien U ons een correct vertrekadres geeft, vinden wij
                    de chauffeur die U vervoerd heeft snel terug.</p>
                <p>Verloren voorwerpen kunnen op de taxicentrale afgehaald worden tussen <b>8u en 17u</b>.</p>
                <p>Het is ook mogelijk om verloren voorwerpen te laten afleveren, dit mits betaling van de rit.</p>
            </section>
            <h2>Klachten</h2>
            <section class="contact__block">
                <p><b>Aan elke klacht die ons bereikt wordt gevolg gegeven</b>.</p>
                <p>Wanneer u ons de correcte gegevens meedeelt kunnen wij vlug de betrokkene terugvinden en interpelleren.</p>
                <p>Klachten in verband met:</p>
                <ul>
                    <li><b>Taxirit:</b> vertrekadres, tijdstip en nummerplaat</li>
                    <li><b>Rijgedrag:</b> tijdstip wangedrag en nummerplaat</li>
                    <li><b>Telefonist:</b> tijdstip contact en of u met een man of vrouw sprak</li>
                </ul>
                <p>Stuur uw klacht naar <a href="mailto:info@v-tax.be">info@v-tax.be</a>.</p>
            </section>
        </section>
    </main>
</template>

<script>
    export default {
        name: "Contact"
    }
</script>